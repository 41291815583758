
import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';

const { fields } = UserModel;

export default {
  name: 'app-email-unverified-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loadingEmailConfirmation',
      email: 'auth/currentUserEmail',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doSendEmailConfirmation:
        'auth/doSendEmailConfirmation',
      doSignout: 'auth/doSignout',
    }),

    async doSubmit() {
      await this.doSendEmailConfirmation(this.model.email);
    },
  },
};
